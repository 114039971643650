import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { InputModule } from '../input/input.module';
import { MenuModule } from '../menu/menu.module';
import { CheckboxGroupModule } from '../checkbox-button-group/checkbox-group.module';
import { SingleDropdownItemsInsertComponent } from './inserts/single-dropdown-items-insert/single-dropdown-items-insert.component';
import { I18nConfig, I18nModule, provideConfig } from '@spartacus/core';
import { ButtonModule } from '../button/button.module';
import { ActivateElementModule } from '../../directives/activate-element/activate-tab-element.module';
import { IconModule } from '../icon/icon.module';
import { ColorThemeModule } from '../shared/color-theme/color-theme.module';
import { TriggerFocusModule } from '@util/directives/trigger-focus/trigger-focus.module';
import { GetVarModule } from '@util/directives/get-var/get-var.module';


@NgModule({
  declarations: [
    DropdownComponent,
    SingleDropdownItemsInsertComponent,
  ],
  imports: [
    CommonModule,
    InputModule,
    IconModule,
    ButtonModule,
    ActivateElementModule,
    MenuModule,
    CheckboxGroupModule,
    I18nModule,
    ColorThemeModule,
    GetVarModule,
    TriggerFocusModule,
  ],
  exports: [
    DropdownComponent,
    ColorThemeModule,

  ],
  providers: [
  ]
})
export class DropdownModule { }
